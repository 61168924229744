<template>
  <div class="myorder">
    <div class="myclass_box wallet_box">
      <div class="wallet_top">
        <div class="wallet_name">
          <div
            class="name_el"
            :class="{ name_choose: choose_wallet == 2 }"
            @click="changeBtn(2)"
          >
            已付款订单
            <div class="choose_line" v-show="choose_wallet == 2"></div>
          </div>
          <div
            class="name_el"
            :class="{ name_choose: choose_wallet == 1 }"
            @click="changeBtn(1)"
          >
            未付款订单
            <div class="choose_line" v-show="choose_wallet == 1"></div>
          </div>
        </div>
      </div>
      <div class="orderbox" v-if="list.length > 0">
        <div class="card" v-for="item in list" :key="item.order_sn">
          <div class="tit">
            <span> 订单编号：{{ item.order_sn }} </span>
            <span v-show="choose_wallet == 2">
              支付时间：{{ item.pay_time ? getLocalTime(item.pay_time) : getLocalTime(0) }}
            </span>
            <span :style="choose_wallet == 1 ? 'margin-left:70px;' : ''">
              <img src="../../assets/img/youhui@2x.png" alt="" /> 已优惠：¥{{
                item.discounts_price
              }}
            </span>
          </div>
          <div
            class="cardlist"
            v-for="child in item.course_list"
            :key="'child' + child.course_id"
          >
            <div class="l">
              <img :src="child.course_cover" alt="" />
            </div>
            <div class="r">
              <div class="rt">{{ child.course_name }}</div>
              <div class="rb">
                价格：<span>¥{{ child.money }}</span>
              </div>
            </div>
          </div>
          <div class="botom">
            <div class="l">
              实付金额：<span>¥{{ item.total_price }}</span>
            </div>
            <div class="r" v-show="choose_wallet == 2" @click="study">
              立即学习 >>
            </div>
            <div class="r2" v-show="choose_wallet == 1">
              <div class="time">
                截止时间：<span>{{ getLocalTime(item.surplus_pay_time) }}</span>
              </div>
              <div class="buton" @click="gopay(item)">立即支付</div>
            </div>
          </div>
        </div>
      </div>
      <div class="nolist" v-else>
        <img src="../../assets/img/wudingdan@2x.png" alt="" />
        <p>暂无订单</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Orderlist",
  data() {
    return {
      choose_wallet: 2,
      list: [],
    };
  },
  created() {
    this.getlist(2);
  },
  methods: {
    getLocalTime(str) {
      var n = parseInt(str) * 1000;
      var D = new Date(n);
      var year = D.getFullYear(); //四位数年份

      var month = D.getMonth() + 1; //月份(0-11),0为一月份
      month = month < 10 ? "0" + month : month;

      var day = D.getDate(); //月的某一天(1-31)
      day = day < 10 ? "0" + day : day;

      var hours = D.getHours(); //小时(0-23)
      hours = hours < 10 ? "0" + hours : hours;

      var minutes = D.getMinutes(); //分钟(0-59)
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // var seconds = D.getSeconds();//秒(0-59)
      // seconds = seconds<10?('0'+seconds):seconds;
      // var week = D.getDay();//周几(0-6),0为周日
      // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

      var now_time =
        year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      return now_time;
    },
    study() {
      this.$emit("studys", {});
    },
    gopay(e) {
      console.log(e);
      this.$router.push({
        path: `/order/order_step2/${e.order_sn}`,
      });
    },
    changeBtn(e) {
      if (this.choose_wallet != e) {
        this.choose_wallet = e;
        this.getlist(e);
      }
    },
    getlist(status) {
      api
        .my_order_list({
          order_status: status,
          create_order_type: 4,
          page_size: 100,
        })
        .then((res) => {
          // console.log(res);
          this.list = res.data.data.list;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.wallet_box {
  .orderbox {
    background: #fff;
    margin-top: 20px;
    padding: 40px 30px;
    .card {
      border: 1px solid #edeff7;
      border-radius: 4px;
      margin-bottom: 20px;
      .botom {
        padding: 30px 10px;
        margin: 0 20px;
        border-top: 1px solid #f5f6f6;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .l {
          font-size: 18px;
          color: #666666;
          font-weight: 400;
          span {
            color: #fb3623;
            font-size: 28px;
          }
        }
        .r2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          .buton {
            width: 180px;
            height: 36px;
            margin-top: 9px;
            line-height: 36px;
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
            font-weight: 500;
            border-radius: 20px;
            background: linear-gradient(90deg, #ff897e 0%, #fe230c 100%);
          }
          .time {
            width: 230px;
            height: 33px;
            border-radius: 20px;
            background: rgba(62, 126, 255, 0.1);
            border: 1px solid #3e7eff;
            color: #3e7eff;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            line-height: 33px;
            position: relative;
            span {
              color: #fb3623;
            }
          }
          .time::after {
            content: "";
            display: block;
            background: url("../../assets/img/2f99cb46afe8a608a74d18420474439.png")
              no-repeat center;
            background-size: 100%;
            width: 10px;
            height: 7px;
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .r {
          font-size: 16px;
          font-weight: 500;
          color: #3e7eff;
          width: 180px;
          height: 36px;
          border: 2px solid #3d7cfe;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          border-radius: 20px;
          background: rgba(62, 126, 255, 0.1);
        }
      }
      .cardlist {
        margin: 15px 20px 20px;
        display: flex;
        align-items: center;
        .l {
          width: 192px;
          height: 120px;
          border-radius: 8px;
          margin-right: 24px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .r {
          .rt {
            font-size: 18px;
            font-weight: 500;
            color: #000b2f;
          }
          .rb {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            span {
              color: #fb3623;
              font-weight: 500;
            }
          }
        }
      }
      .tit {
        display: flex;
        align-items: center;
        background: #f5f6f6;
        padding: 17px 20px;
        line-height: 1;
        span {
          font-size: 16px;
          color: #666666;
          font-weight: 400;
        }
        span:nth-child(2) {
          margin: 0 70px;
        }
        span:last-child {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #fb3623;
          img {
            width: 23px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .wallet_top {
    width: 100%;
    // height: 122px;
    background: #fff;

    .wallet_name {
      height: 61px;
      border-bottom: 1px solid #f5f5f5;

      .name_el {
        height: 61px;
        line-height: 61px;
        display: inline-block;
        text-align: center;
        margin-left: 30px;
        margin-right: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        cursor: pointer;

        .choose_line {
          // position: absolute;
          width: 60px;
          height: 3px;
          background: #3e7eff;
          margin: -3px auto 0;
        }
      }

      .name_choose {
        color: #3e7eff;
      }
    }
  }
}
.nolist {
  width: 950px;
  height: 656px;
  background: #fff;
  box-sizing: border-box;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 286px;
    height: 223px;
    // display: block;
    margin: 141px 0 24px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}
</style>