<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- banner -->
    <div class="content_box">
      <!-- <img class="banner_box" :src="this.url.imgUrl + bannerImg" alt="勤学教育" /> -->
      <div id="content">
        <!-- 侧边导航栏 -->
        <div class="slide_nav_box">
          <div class="slide_el">
            <div class="myClass">我的课程</div>
            <div
              class="target_el"
              v-for="item in class_list"
              :key="item.id"
              @click="goCard(item)"
              :class="{ target_el_choose: choose_nav == item.id }"
              @mouseover="cutImg(item, $event)"
              @mouseleave="resetImg(item, $event)"
            >
              <img
                id="none"
                class="icon_img"
                :src="item.icon_hover"
                alt
                v-if="item.id == choose_nav"
              />
              <img class="icon_img" :src="item.icon" alt v-else />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="slide_el">
            <div class="myClass">我的题库</div>
            <div
              class="target_el"
              v-for="item in topic_list"
              :key="item.id"
              @click="goCard(item)"
              :class="{ target_el_choose: choose_nav == item.id }"
              @mouseover="cutImg(item, $event)"
              @mouseleave="resetImg(item, $event)"
            >
              <img
                id="none"
                class="icon_img"
                :src="item.icon_hover"
                alt
                v-if="item.id == choose_nav"
              />
              <img class="icon_img" :src="item.icon" alt v-else />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="slide_el">
            <div class="myClass">个人管理</div>
            <div
              class="target_el"
              v-for="item in personal_list"
              :key="item.id"
              @click="goCard(item)"
              :class="{ target_el_choose: choose_nav == item.id }"
            >
              <img
                id="none"
                class="icon_img"
                :src="item.icon_hover"
                alt
                v-if="item.id == choose_nav"
              />
              <img class="icon_img" :src="item.icon" alt v-else />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="slide_el">
            <div class="myClass">其他</div>
            <div
              class="target_el"
              v-for="item in other_list"
              :key="item.id"
              @click="goCard(item)"
              :class="{ target_el_choose: choose_nav == item.id }"
            >
              <img
                id="none"
                class="icon_img"
                :src="item.icon_hover"
                alt
                v-if="item.id == choose_nav"
              />
              <img class="icon_img" :src="item.icon" alt v-else />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- 我的课程 -->
        <div class="myclass_box" v-if="choose_nav == 1">
          <!-- <div class="class_title">
            <div>我的课程</div>
          </div> -->
          <!-- {{ coode_status.data.list }} -->
          <!-- 未开课 -->
          <!-- <div
            class="no_class"
            v-if="
              (coode_status.code == 1 && personal == []) || personal.length == 0
            "
          >
            <img src="../../assets/img/wukemu.png" alt />
            <p>
              当前暂未开通课程，请联系客服进行开课
              <br />服务热线：400-656-1156
            </p>
          </div> -->

          <!-- 已开课程 -->
          <!-- <div class="class_box"> -->
          <Studysroom :list="personal" />
          <!-- </div> -->
        </div>

        <!-- 直播课 -->
        <div class="myclass_box" v-show="choose_nav == 33">
          <!-- 导航 -->
          <div class="nav_box">
            <!-- 一级导航 -->
            <div class="one_box">
              <div>{{ live.one_name.name }}</div>
              <div class="choose_btn" @click="live.dialogVisible = true">
                切换课程
                <img src="../../assets/img/xuanze.png" alt />
              </div>
            </div>
            <!-- 二级导航 -->

            <div
              class="two_box"
              :style="live.two_nav.length > 0 ? 'margin-top: 26px;' : ''"
            >
              <div class="two_right" style="margin-left: 0">
                <div
                  class="major_el"
                  :class="live.choose_two == item.id ? 'major' : ''"
                  v-for="item in live.two_nav"
                  :key="item.chapter_category_id"
                  @click="cutList(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- {{ coode_status.data.list }} -->
          <!-- 没有直播 -->
          <div
            class="no_class zbk"
            v-if="choose_nav == 33 && live.list.length == 0"
          >
            <img src="../../assets/img/zhobo.png" alt />
            <p>
              直播老师正在准备中，直播上线将会提前推送给您哦~～
              <br />
            </p>
          </div>

          <!-- 直播课 -->
          <div class="class_box" v-else style="background: #fff">
            <div
              class="class_el live_card"
              v-for="(item, index) in live.list"
              :key="index + 'live'"
            >
              <div class="time_line_box">
                <div class="circular"></div>
                <div class="time" v-if="item.date.length == 1">
                  <p></p>
                  <p>{{ item.date[0] }}</p>
                </div>
                <div class="time" v-else>
                  <p>{{ item.date[0] }}</p>
                  <p>{{ item.date[1] }}月 {{ item.date[2] }}日</p>
                </div>
                <div class="line"></div>
              </div>

              <div
                class="class_info_box"
                v-for="a in item.live"
                :key="a.start_time"
                @click="_live_detail(a.live_id)"
              >
                <img
                  :src="a.live_images"
                  alt="勤学教育"
                  style="margin-top: 0"
                />
                <div class="personal_txt">
                  <div class="h2">{{ a.live_title }}</div>
                  <div class="live_center">
                    <span>
                      <img src="../../assets/img/timelive.png" alt />
                      开始时间：{{
                        a.dispark_time.split(" ")[1].split(":")[0]
                      }}:{{ a.dispark_time.split(" ")[1].split(":")[1] }}
                    </span>
                    <span>
                      <img src="../../assets/img/kemu_live.png" alt />
                      科目：{{ a.cate_name }}
                    </span>
                  </div>
                  <div class="live_botom">
                    <div class="img">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="a.teacher_image"
                        fit="cover"
                      ></el-image>
                    </div>
                    {{ a.teacher_name }}
                  </div>
                </div>
                <div
                  :class="a.n == 1 ? 'z' : a.n == 2 ? 'j' : 'e'"
                  type="button"
                >
                  <img
                    :src="
                      a.n == 1
                        ? require('../../assets/img/zhibozhong@2x.png')
                        : a.n == 2
                        ? require('../../assets/img/jijiang@2x.png')
                        : require('../../assets/img/jieshu@2x.png')
                    "
                    alt
                  />
                  {{ a.n == 1 ? "直播中" : a.n == 2 ? "即将开始" : "直播结束" }}
                </div>
              </div>
            </div>
          </div>
          <el-dialog
            title="请选择专业科目"
            :visible.sync="live.dialogVisible"
            width="32%"
          >
            <!-- <span>这是一段信息</span> -->
            <div class="chapter_category_box">
              <div
                class="chapter_category_el"
                v-for="item in live.one_nav"
                :key="item.id"
                @click="choose_major(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <!-- <el-button @click="live.dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="live.dialogVisible = false">确 定</el-button>-->
            </span>
          </el-dialog>
        </div>
        <!-- 重读申请 -->
        <div class="myclass_box" v-if="choose_nav == 555">
          <Stress @call="goCard(personal_list[3])" />
        </div>

        <!-- 我的钱包 -->
        <div class="myclass_box wallet_box" v-if="choose_nav == 5">
          <div class="wallet_top">
            <div class="wallet_name">
              <div
                class="name_el"
                :class="{ name_choose: choose_wallet == 1 }"
                @click="changeBtn(1)"
              >
                红包(0)
                <div class="choose_line" v-show="choose_wallet == 1"></div>
              </div>
              <div
                class="name_el"
                :class="{ name_choose: choose_wallet == 2 }"
                @click="changeBtn(2)"
              >
                优惠券(0)
                <div class="choose_line" v-show="choose_wallet == 2"></div>
              </div>
            </div>
            <div class="btn_box" v-if="choose_wallet == 1">
              <button
                :class="{ choose_btn: choose_btn == 1 }"
                @click="toogle(1)"
              >
                可用红包&nbsp;&nbsp;(0)
              </button>
              <button
                :class="{ choose_btn: choose_btn == 2 }"
                @click="toogle(2)"
              >
                已失效红包&nbsp;&nbsp;(0)
              </button>
            </div>
            <div class="btn_box" v-else>
              <button
                :class="{ choose_btn: choose_btn == 1 }"
                @click="toogle(1)"
              >
                可用优惠券&nbsp;&nbsp;(0)
              </button>
              <button
                :class="{ choose_btn: choose_btn == 2 }"
                @click="toogle(2)"
              >
                已失效优惠券&nbsp;&nbsp;(0)
              </button>
            </div>
          </div>
          <div class="wallet_down">
            <div v-if="choose_wallet == 1">
              <img src="../../assets/img/hongbao.png" alt />
              <div>亲爱的同学，你还没有红包哦～</div>
            </div>
            <div v-else>
              <img src="../../assets/img/youhuiquan.png" alt />
              <div>亲爱的同学，你还没有优惠券哦～</div>
            </div>
            <!-- <div>亲爱的同学，你还没有优惠券哦～</div> -->
          </div>
          <!-- 钱包 -->
          <div class="class_box"></div>
        </div>

        <!-- 我的订单 -->
        <div class="myclass_box" v-if="choose_nav == 55">
          <Myorder @studys="choose_nav = 1" />
        </div>

        <!-- 我的班主任 -->
        <div class="myclass_box headmaster_box" v-if="choose_nav == 6">
          <div class="class_title_box">
            <div>
              <p>我的班主任</p>
              <div class="line"></div>
            </div>
          </div>
          <!-- 班主任 -->
          <div class="headmaster_down">
            <div class="name_desc_box">
              <div
                class="name_desc_top"
                v-if="
                  teacher_info.nickname && teacher_info.teacher_message != ''
                "
              >
                <div class="desc_top_content">
                  <div class="teacher_name">
                    <p>{{ teacher_info.nickname }}</p>
                  </div>
                  <div class="desc_txt">{{ teacher_info.teacher_message }}</div>
                </div>
                <div class="teacher_line"></div>
              </div>
              <div class="no_info_txt" v-else>暂未上传</div>
              <div class="name_desc_down">
                <div
                  class="call_teacher"
                  v-if="
                    teacher_info.nickname && teacher_info.teacher_message != ''
                  "
                >
                  联系班主任
                </div>
                <div class="contact_info">
                  <div class="contact_el">
                    <img src="../../assets/img/shouji.png" alt />
                    <div class="contact_right">
                      <div class="number">
                        <p>手机号</p>
                        <p v-if="teacher_info.phone != ''">
                          {{ teacher_info.phone }}
                        </p>
                        <p v-else>暂未上传</p>
                      </div>
                      <button
                        type="button"
                        @click="copyText(1)"
                        :class="{ disabled: teacher_info.phone == '' }"
                      >
                        复制
                      </button>
                    </div>
                  </div>
                  <div class="contact_el">
                    <img
                      style="width: 40px; height: 34px"
                      src="../../assets/img/weixin.png"
                      alt
                    />
                    <div class="contact_right">
                      <div class="number">
                        <p>微信号</p>
                        <p v-if="teacher_info.wechat_number != ''">
                          {{ teacher_info.wechat_number }}
                        </p>
                        <p v-else>暂未上传</p>
                      </div>
                      <button
                        type="button"
                        @click="copyText(2)"
                        :class="{ disabled: teacher_info.wechat_number == '' }"
                      >
                        复制
                      </button>
                    </div>
                  </div>
                  <div class="contact_el">
                    <img
                      style="width: 34px; height: 38px"
                      src="../../assets/img/qq.png"
                      alt
                    />
                    <div class="contact_right">
                      <div class="number">
                        <p>QQ号</p>
                        <p v-if="teacher_info.qq_number != ''">
                          {{ teacher_info.qq_number }}
                        </p>
                        <p v-else>暂未上传</p>
                      </div>
                      <button
                        type="button"
                        @click="copyText(3)"
                        :class="{ disabled: teacher_info.qq_number == '' }"
                      >
                        复制
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 修改资料 -->
        <div class="myclass_box headmaster_box" v-if="choose_nav == 66">
          <div class="class_title_box">
            <div>
              <p>修改资料</p>
              <div class="line"></div>
            </div>
          </div>
          <!-- 班主任 -->
          <div class="headmaster_down">
            <div class="name_desc_box">
              <el-form
                style="width: 50%; margin-top: 30px"
                ref="sizeForm"
                :model="sizeForm"
                label-width="80px"
                :rules="regRules"
                size="mini"
              >
                <el-form-item label="姓名" prop="agreement_fullname">
                  <el-input v-model="sizeForm.agreement_fullname"></el-input>
                </el-form-item>
                <el-form-item label="活动区域" prop="agreement_cert_type">
                  <el-select
                    v-model="sizeForm.agreement_cert_type"
                    placeholder="请选择证件类型"
                  >
                    <el-option label="身份证" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号" prop="agreement_cert_number">
                  <el-input v-model="sizeForm.agreement_cert_number"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="agreement_contact">
                  <el-input v-model="sizeForm.agreement_contact"></el-input>
                </el-form-item>
                <el-form-item size="large">
                  <el-button type="primary" @click="onSubmit"
                    >提交修改</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <!-- 我的购课协议 -->
        <div class="myclass_box headmaster_box" v-if="choose_nav == 666">
          <div class="class_title_box">
            <div>
              <p>我的购课协议</p>
              <div class="line"></div>
            </div>
          </div>
          <div class="headmaster_down">
            <div class="name_desc_box contract class_box">
              <div
                class="class_el"
                v-for="item in agreement_array"
                :key="item.id"
              >
                <div class="class_info_box">
                  <img style="margin: 0" :src="item.cover" alt="勤学教育" />
                  <div style="display: flex; flex-direction: column">
                    <a>
                      <div class="personal_txt">
                        <div>{{ item.title }}</div>
                      </div>
                      <button
                        class="now_study"
                        type="button"
                        @click="opendialog(item)"
                      >
                        查看 >>
                      </button>
                      <!-- @click="goMyclass(item.courses_id)" -->
                    </a>
                    <p class="ml23">{{ item.status_code }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 版心结尾 -->
      </div>
    </div>
    <Foot></Foot>
    <el-dialog :title="showurl.title" :visible.sync="dialogVisible" width="50%">
      <div class="contract_desc">
        <iframe
          style="width: 100%; height: 100%"
          :src="showurl.button.url"
          frameborder="0"
        ></iframe>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">同 意</el-button>
      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
import Myorder from "../../components/orderlist";
import Studysroom from "../../components/mystudys";
import { checkPhone, idcard } from "@/utils/validate";
import Stress from "../../components/stress/index.vue";
export default {
  components: {
    Myorder,
    Studysroom,
    Stress,
  },
  data() {
    var validatorPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
        return;
      }
      if (!checkPhone(value)) {
        callback(new Error("手机号格式错误"));
        return;
      }
      callback();
    };
    var validatorID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入证件号"));
        return;
      }
      if (!idcard(value)) {
        callback(new Error("证件号格式错误"));
        return;
      }
      callback();
    };
    var validatorName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
        return;
      }
      callback();
    };
    return {
      //购课协议
      showurl: {
        button: {
          url: "",
        },
        tittle: "",
      },
      dialogVisible: false,
      agreement_array: [],
      // 修改资料
      onSubmitsure: false,
      sizeForm: {
        agreement_fullname: "",
        agreement_cert_type: "1",
        agreement_cert_number: "",
        agreement_contact: "",
      },
      regRules: {
        // agreement_fullname: [{ required: true, trigger: 'change', validator: validatorName }],
        // agreement_cert_number: [{ required: true, trigger: 'blur', validator: validatorID }],
        // agreement_contact: [{ required: true, trigger: 'blur', validator: validatorPhone }],
      },
      choose_nav: 1, // 选中导航
      choose_wallet: 1, // 我的钱包选中效果
      choose_btn: 1, // 我的钱包选中效果
      choose: false,
      disabled: false,
      disabled2: false,
      disabled3: false,
      coode_status: [], // code状态
      teacher_info: {}, // 老师信息
      //直播课
      live: {
        one_nav: [],
        two_nav: [],
        one_name: {},
        choose_two: 0,
        dialogVisible: false,
        list: [],
      },
      // 我的课程
      class_list: [
        {
          id: 1,
          name: "开始学习",
          icon: require("../../assets/img/xuexi_normal.png"),
          icon1: require("../../assets/img/xuexi_normal.png"),
          icon_hover: require("../../assets/img/xuexi_hover.png"),
          routerName: "",
        },
        {
          id: 33,
          name: "直播课",
          icon: require("../../assets/img/zhibo_normal.png"),
          icon1: require("../../assets/img/zhibo_normal.png"),
          icon_hover: require("../../assets/img/zhibo_hover.png"),
          routerName: "",
        },
      ],
      //   我的题库
      topic_list: [
        {
          id: 2,
          name: "开始做题",
          icon: require("../../assets/img/zuoti_normal.png"),
          icon1: require("../../assets/img/zuoti_normal.png"),
          icon_hover: require("../../assets/img/zuoti_hover.png"),
          routerName: "Chapter",
          select_id: 1,
        },
        {
          id: 3,
          name: "错题本",
          icon: require("../../assets/img/cuoti_normal (1).png"),
          icon1: require("../../assets/img/cuoti_normal (1).png"),
          icon_hover: require("../../assets/img/cuoti_hover.png"),
          routerName: "WrongQuestion",
          select_id: 6,
        },
        {
          id: 4,
          name: "我的收藏",
          icon: require("../../assets/img/shoucang_normal (1).png"),
          icon1: require("../../assets/img/shoucang_normal (1).png"),
          icon_hover: require("../../assets/img/shoucang_hover.png"),
          routerName: "Collect",
          select_id: 7,
        },
      ],
      // 个人管理
      personal_list: [
        {
          id: 555,
          name: "重读申请",
          icon: require("../../assets/img/chongdu@2x.png"),
          icon1: require("../../assets/img/chongdu@2x.png"),
          icon_hover: require("../../assets/img/chongdu-dianji@2x.png"),
          routerName: "",
        },
        {
          id: 5,
          name: "我的钱包",
          icon: require("../../assets/img/qianbao_normal.png"),
          icon1: require("../../assets/img/qianbao_normal.png"),
          icon_hover: require("../../assets/img/qianbao_hover.png"),
          routerName: "",
        },

        {
          id: 55,
          name: "我的订单",
          icon: require("../../assets/img/dingdan_normal@2x.png"),
          icon1: require("../../assets/img/dingdan_hover@2x.png"),
          icon_hover: require("../../assets/img/dingdan_hover@2x.png"),
          routerName: "",
        },
        {
          id: 6,
          name: "我的班主任",
          icon: require("../../assets/img/banzhuren_normal.png"),
          icon1: require("../../assets/img/banzhuren_normal.png"),
          icon_hover: require("../../assets/img/banzhuren_hover.png"),
          routerName: "",
        },
        {
          id: 66,
          name: "修改资料",
          icon: require("../../assets/img/xiugai_normal@2x.png"),
          icon1: require("../../assets/img/xiugai_hover@2x.png"),
          icon_hover: require("../../assets/img/xiugai_hover@2x.png"),
          routerName: "",
        },
        {
          id: 666,
          name: "我的购课协议",
          icon: require("../../assets/img/goukexuzhi_normal@2x.png"),
          icon1: require("../../assets/img/goukexuzhi_normal@2x.png"),
          icon_hover: require("../../assets/img/goukexuzhi_hover@2x.png"),
          routerName: "",
        },
      ],
      //   其他
      other_list: [
        {
          id: 7,
          name: "用户协议",
          icon: require("../../assets/img/xieyi_normal.png"),
          icon1: require("../../assets/img/xieyi_normal.png"),
          icon_hover: require("../../assets/img/xieyi_hover.png"),
          routerName: "",
          url: "https://api.qinxuejiaoyu.com/user-agreement.html",
        },
        {
          id: 8,
          name: "隐私政策",
          icon: require("../../assets/img/yinsi_normal.png"),
          icon1: require("../../assets/img/yinsi_normal.png"),
          icon_hover: require("../../assets/img/yinsi_hover.png"),
          routerName: "",
          url: "https://api.qinxuejiaoyu.com/privacy-policy.html",
        },
      ],
      wallet_box: [
        {
          id: 1,
          name: "红包（0）",
        },
      ],
      bannerImg: "/assets/img/personal.jpg", //banner
      personal: {},
      code: "",
    };
  },
  filters: {
    sliceStr(str) {
      return str.substring(0, 4);
    },
    day(str) {
      let mon = str.substr(5, 2);
      let day = str.substr(8, 2);
      return (str = mon + "月" + day + "日");
    },
  },
  methods: {
    //打开购课协议
    opendialog(val) {
      this.showurl = val;
      this.dialogVisible = true;
    },
    //修改资料
    onSubmit() {
      this.$refs.sizeForm.validate((valid) => {
        if (
          this.sizeForm.agreement_cert_number == "" &&
          this.sizeForm.agreement_contact == "" &&
          this.sizeForm.agreement_fullname == ""
        ) {
          this.$message.error("暂无修改");
        } else {
          if (this.onSubmitstrue) {
            this.$message.error("请勿频繁操作");
          } else {
            this.onSubmitstrue = true;
            api.save_agreement_info(this.sizeForm).then((res) => {
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.onSubmitstrue = false;
              }, 30000);
            });
          }
        }
      });
    },
    //直播课
    //直播课详情
    _live_detail(val) {
      let data = {
        live_id: val,
      };
      api.live_detail(data).then((res) => {
        if (res.data.code == 1) {
          //  console.log(res.data.data.url)
          window.open(res.data.data.url);
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        } else if (parseInt(res.data.code) == 0) {
          if (res.data.data.error_code == 4) {
            this.dialogVisible = true;
            this.show_type = 2;
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    name() {
      this.one_name = this.value.name;
      // console.log("下拉", this.value);
      this.getTwoChapter(this.value.id);
    },
    //直播列表
    live_list(data) {
      api.live_list(data).then((res) => {
        if (res.data.code == 1) {
          res.data.data.list.map((e) => {
            e.date = e.date.split("-");
          });
          this.live.list = res.data.data.list;
        } else {
          this.live.list = [];
        }
      });
    },
    //   获取一级章节分类
    getOneChapter() {
      api.category_list().then((res) => {
        // // console.log("一级分类", res);
        if (parseInt(res.data.code) == 1) {
          let v = res.data.data.list;
          let c = [];
          res.data.data.list.forEach((e) => {
            if (e.children) {
              c = [...c, ...e.children];
            }
          });
          // console.log(c);
          this.live.one_nav = c;
          this.live.one_name = c[0];
          this.getTwoChapter(c[0].chapter_category);
          // console.log("下拉", this.value);
        }
      });
    },
    choose_major(el) {
      // // console.log("章节", el);
      this.getTwoChapter(el.chapter_category);
      this.live.one_name = el;
      this.live.dialogVisible = false;
    },
    //   获取二级分类
    getTwoChapter(id) {
      var data = {
        chapter_category: id,
      };
      api.my_live_chapter_cate(data).then((res) => {
        // // console.log("二级分类", res);
        // console.log(res);
        if (parseInt(res.data.code) == 1) {
          this.live.two_nav = res.data.data.list;
          this.live.choose_two = res.data.data.list[0].id;
          // console.log(this.live.two_nav);
          let data = {
            three_chaptercate_id: res.data.data.list[0].id,
            chapter_category: id,
          };
          this.live_list(data);
        } else {
          this.$message.error(res.data.msg);
          this.live.two_nav = [];
          this.live.list = [];
        }
      });
    },
    // 点击二级导航切换列表
    cutList(el) {
      this.live.choose_two = el.id;
      let data = {
        three_chaptercate_id: el.id,
        chapter_category: this.live.one_name.chapter_category,
      };
      // console.log(el.id)
      console.log(this.live.one_name);
      this.live_list(data);
    },
    //   鼠标进入切换图片
    cutImg(item, e) {
      item.icon = item.icon_hover;
    },
    // 鼠标离开恢复图标
    resetImg(item, e) {
      item.icon = item.icon1;
    },
    // 点击切换模块
    goCard(item) {
      this.choose = true;
      if ([2, 3, 4].includes(item.id)) {
        this.$router.push({
          name: item.routerName,
          params: {
            is_pageid: 9,
            select_id: item.select_id,
          },
        });
      } else if ([7, 8].includes(item.id)) {
        window.open(item.url, "_blank");
        return false;
        // window.location.href=item.url
      }
      this.choose_nav = item.id;
    },
    // 课程接口
    // getPersonal() {
    //   api.get_user_project().then((res) => {
    //     // console.log(res);
    //     // this.coode_status = res.data;
    //     if (res.data.code == 1) {
    //       this.personal = res.data.data;
    //       // console.log(this.personal)
    //     }
    //   });
    // },
    // 我的班主任
    myTeacher() {
      api.myteacher().then((res) => {
        if (res.data.code == 1) {
          console.log(res);
          this.teacher_info = res.data.data;
        }
        // console.log("班主任====", res);
      });
    },
    // 我的钱包切换
    changeBtn(id) {
      this.choose_wallet = id;
    },
    // 我的钱包 按钮切换
    toogle(id) {
      this.choose_btn = id;
    },
    // 去我的课程详情页
    goMyclass(id) {
      this.$router.push({ name: "MyClass", params: { course_id: id } });
    },
    // 点击复制
    copyText(id) {
      // if (
      //   this.teacher_info.phone == "" ||
      //   this.teacher_info.wechat_number == "" ||
      //   this.teacher_info.qq_number == ""

      // ) {
      //   return false;
      // } else {
      var input = document.createElement("input"); // js创建一个input输入框
      if (id == 1) {
        input.value = this.teacher_info.phone; // 将需要复制的文本赋值到创建的input输入框中
      } else if (id == 2) {
        input.value = this.teacher_info.wechat_number;
      } else {
        input.value = this.teacher_info.qq_number;
      }

      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message.success({
        duration: 1500,
        message: "复制成功!",
      });
      // }
    },
  },
  created() {
    if (this.$route.query.type && this.$route.query.type == 2) {
      this.choose_nav = 33;
    }
    if (this.$route.query.type && this.$route.query.type == 3) {
      this.choose_nav = 6;
    }
    this.myTeacher();
    let token = localStorage.getItem("token");
    if (token) {
      // this.getPersonal();
    } else {
      // this.$message.error({
      //   duration:1500,
      //   message:"请登录后查看!"
      // })
      // this.$router.push({ path: "/login" });
      let routeData = this.$router.resolve({ path: "/login" });
      window.open(routeData.href, "_blank");
      // const link =
      // window.open(link.href, "_blank"); //打开新窗口
    }
    this.getOneChapter();
    api.agreement_list().then((res) => {
      if (res.data.code == 1) {
        this.agreement_array = res.data.data.list;
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
// @import url("./index.css");
.banner_box {
  width: 100%;
  height: 400px;
  background: #eee;
}

.banner_box img {
  width: 100%;
  height: 100%;
}

/* 导航 */

#nav_bar {
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/* 版心 */
.content_box {
  overflow: hidden;
  background: #f5f5f5;

  #content {
    width: 1200px;
    margin: auto;
    // background: #fff;
    display: flex;
    // justify-content: space-between;
    margin-bottom: 187px;
    margin-top: 30px;

    .slide_nav_box {
      width: 230px;
      height: 100%;
      background: #fff;
      margin-right: 20px;

      .slide_el {
        // 我的课程
        .myClass {
          height: 45px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          padding-left: 36px;
          line-height: 45px;
        }

        .target_el {
          height: 35px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          border-left: 3px solid #fff;
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon_img {
            width: 17px;
            height: 15px;
            margin: 0 8px 0 32px;
          }

          .icon_img2 {
            background: url("../../assets/img/zuoti_normal.png") no-repeat;
            background-size: 15px 15px;
          }

          .icon1 {
            background: url("../../assets/img/xuexi_hover.png") no-repeat;
            background-size: 15px 15px;
          }
        }

        .target_el_choose {
          color: #3a99f9;
          border-left: 3px solid #3a99f9;
          // background: rgba(62, 126, 255, .5);
          background: #ebf2ff;
        }

        .target_el:hover {
          color: #3a99f9;
        }
      }
    }

    // 我的课程
    .myclass_box {
      width: 950px;

      .class_title {
        width: 920px;
        height: 62px;
        background: #fff;
        padding-left: 30px;

        div {
          width: 65px;
          height: 59px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3a99f9;
          line-height: 62px;
          border-bottom: 3px solid #3a99f9;
        }
      }

      .zbk {
        height: 417px !important;
      }

      // 未开课
      .no_class {
        width: 950px;
        height: 423px;
        background: #fff;
        display: flex;
        flex-flow: column;
        align-items: center;
        overflow: hidden;
        margin-top: 10px;

        img {
          width: 200px;
          // height: 224px;
          margin: 70px 0 16px;
        }

        p {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          text-align: center;
        }
      }

      // 课程
      .class_box {
        width: 950px;
        // height: 500px;
        // background: #fff;
        margin-top: 10px;
        overflow: hidden;

        .class_el:first-child {
          margin-top: 30px;
        }

        .class_el:last-child {
          margin-bottom: 40px;
        }

        .class_el {
          margin-top: 49px;

          .time_line_box {
            display: flex;
            align-items: center;

            .circular {
              width: 10px;
              height: 10px;
              border: 2px solid #2a2c2f;
              border-radius: 50%;
              margin: 0 20px;
            }

            .time {
              display: flex;
              align-items: flex-end;

              // justify-content: flex-end;
              p:nth-child(1) {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-right: 11px;
              }

              p:nth-child(2) {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }

            .line {
              width: 726px;
              height: 1px;
              background: #efefef;
              margin-left: 18px;
            }
          }

          .class_info_box {
            display: flex;
            align-items: center;

            img {
              width: 192px;
              height: 120px;
              border-radius: 8px;
              margin-left: 30px;
              margin-top: 27px;
            }

            a {
              display: flex;

              .personal_txt {
                width: 334px;
                // max-width: 334px;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000b2f;
                margin: 0 170px 0 23px;
              }

              .now_study {
                width: 180px;
                height: 36px;
                border: 2px solid #3d7cfe;
                border-radius: 18px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                cursor: pointer;
                color: #3e7eff;
                background: #ebf2ff;
              }
            }
          }
        }
      }
    }

    // 我的钱包
    .wallet_box {
      .wallet_top {
        width: 100%;
        height: 122px;
        background: #fff;

        .wallet_name {
          height: 61px;
          border-bottom: 1px solid #f5f5f5;

          .name_el {
            height: 61px;
            line-height: 61px;
            display: inline-block;
            text-align: center;
            margin-left: 30px;
            margin-right: 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;

            .choose_line {
              // position: absolute;
              width: 60px;
              height: 3px;
              background: #3e7eff;
              margin: -3px auto 0;
            }
          }

          .name_choose {
            color: #3e7eff;
          }
        }

        .btn_box {
          height: 61px;
          display: flex;
          align-items: center;

          button:first-child {
            margin: 0 20px 0 30px;
          }

          button {
            // width: 130px;
            padding: 0 13px;
            height: 26px;
            background: #f5f5f5;
            border: 1px solid #dddddd;
            border-radius: 13px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }

          .choose_btn {
            color: #fff;
            background: #3d7cfe;
            border: none;
          }
        }
      }

      .wallet_down {
        width: 950px;
        height: 363px;
        background: #fff;
        margin-top: 10px;
        display: flex;
        flex-flow: column;
        // justify-content: center;
        align-items: center;

        div {
          img {
            width: 200px;
            // height: 224px;
            margin: 80px 0 16px;
          }

          div {
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }

    // 我的班主任
    .headmaster_box {
      .class_title_box {
        width: 950px;
        height: 62px;
        background: #fff;

        div {
          // width: 100%;
          display: inline-block;
          height: 62px;
          position: relative;
          margin-left: 29px;

          p {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #3e7eff;
            line-height: 62px;
            text-align: center;
          }

          .line {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 60px;
            height: 3px;
            background: #3e7eff;
          }
        }
      }

      .name_desc_box {
        width: 890px;
        min-height: 423px;
        padding: 0 30px;
        background: #fff;
        margin-top: 10px;
        overflow: hidden;

        .name_desc_top {
          margin-top: 30px;

          .desc_top_content {
            width: 890px;
            // min-height: 194px;
            background: #f6faff;
            overflow: hidden;

            .teacher_name {
              position: relative;
              width: 207px;
              height: 63px;
              background: url("../../assets/img/banzhuren.png") no-repeat;
              background-size: 207px 63px;
              margin-top: 22px;

              p {
                position: absolute;
                top: -11px;
                left: 29px;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
              }
            }

            .desc_txt {
              width: 832px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              margin: 25px auto;
            }
          }

          .teacher_line {
            width: 890px;
            height: 1px;
            background: #f5f5f5;
            margin-top: 36px;
          }
        }

        .no_info_txt {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin: 30px 0 20px;
        }

        .name_desc_down {
          margin-top: 30px;

          .call_teacher {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-bottom: 20px;
          }

          .contact_info {
            width: 890px;
            display: flex;
            justify-content: space-between;

            .contact_el {
              width: 284px;
              height: 90px;
              background: #ffffff;
              border: 2px solid #f5f5f5;
              border-radius: 10px;
              display: flex;
              align-items: center;

              img {
                width: 31px;
                height: 40px;
                margin: 0 19px 0 22px;
              }

              .contact_right {
                width: 193px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .number {
                  p:nth-child(1) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }

                  p:nth-child(2),
                  p:nth-child(3) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                  }
                }

                button {
                  width: 78px;
                  height: 30px;
                  background: #3e7eff;
                  border: 2px solid #3e7eff;
                  border-radius: 15px;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  cursor: pointer;
                }

                .disabled {
                  pointer-events: none;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }

    /* 个人中心内容 */

    .personal_box {
      width: 100%;
      /* height: 300px; */
      /* background: pink; */
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      flex-flow: column;
      /* justify-content: space-between; */
      margin-bottom: 122px;
      display: none;

      .personal_item {
        width: 100%;
        height: 160px;
        border-radius: 8px 8px 0px 0px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
        /* margin-right: 15px; */
        /* margin: 0 15px 20px 0; */
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
          width: 192px;
          height: 120px;
          border-radius: 8px;
          margin: 0 29px 0 20px;
        }

        .personal_txt {
          width: 708px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          /* margin: auto; */
          div:nth-child(1) {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000b2f;
            margin: 19px 0 17px;
            line-height: 18px;
          }

          div:nth-child(2) {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
          }
        }

        button {
          cursor: pointer;
        }

        .now_study {
          width: 200px;
          height: 36px;
          background: linear-gradient(259deg, #3e7eff 0%, #2765f3 100%);
          border-radius: 18px;
          border: none;
          outline: none;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          margin-left: 30px;
        }
      }
    }
  }

  // .personal_item .personal_item .personal_txt .personal_item
}

//   导航
.nav_box {
  // height: 170px;
  padding-left: 20px;
  background: #fff;
  // border-radius: 6px;
  // margin: 0px 0 30px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  //   一级导航
  .one_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 19px 0 0px;

    div {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
    }

    .select_option {
      position: relative;
      margin-left: 12px;

      /deep/.el-select {
        width: 96px;
        height: 26px;
        background: #3e7eff;
        border-radius: 13px;

        .el-input__inner {
          width: 96px;
          height: 26px;
          // color: red;
          color: rgba(0, 0, 0, 0);
          background: #3e7eff;
          border-radius: 13px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          border: none;
          background-image: url("../../assets/img/qiehuan.png");
          background-repeat: no-repeat;
          background-size: 13px 12px;
          background-position-x: 72px;
          background-position-y: 7px;
          // display: none;
        }

        .el-input__inner::placeholder {
          color: rgba(0, 0, 0, 0);
          padding-left: 11px;
        }

        .el-input__suffix-inner {
          display: none;
        }
      }

      p {
        position: absolute;
        top: 6px;
        left: 11px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #fff;
        // z-index: -1;
        // z-index: 999;
      }
    }

    .choose_btn {
      width: 81px;
      // height: 23px;
      border: 1px solid #3e7eff;
      border-radius: 11px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;

      img {
        width: 12px;
        height: 8px;
        margin-left: 3px;
      }
    }
  }
}

//   二级导航
.two_box {
  width: 100%;
  // min-height: 65px;
  display: flex;

  .two_left {
    width: 70px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #484848;
    padding: 7px 0;
    // padding-top: 7px;
  }

  .two_right {
    width: 997px;
    margin-left: 49px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;

    .major_el {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #202b3a;
      text-align: center;
      padding: 3px 19px;
      cursor: pointer;
    }

    .major {
      color: #fff;
      background: #3e7eff;
      border-radius: 18px;
      // max-height: 30px;
      // padding-bottom: 7px;
    }
  }
}

.chapter_category_box {
  width: 100%;
  display: flex;

  .chapter_category_el {
    cursor: pointer;
    margin-right: 30px;
  }

  .chapter_category_el:hover {
    color: #3e7eff;
  }
}

.live_card {
  .class_info_box {
    margin-top: 30px;
    position: relative;
    cursor: pointer;
  }

  .time {
    min-width: 140px;
  }

  .line {
    width: 700px !important;
  }

  .personal_txt {
    margin-left: 20px;

    .h2 {
      color: #000b2f;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 11px;
    }

    .live_center {
      font-size: 14px;
      font-weight: 400;
      color: #888888;

      span {
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 11px;
        margin-right: 10px;
        background: #f5f5f5;

        img {
          width: 12px !important;
          height: 12px !important;
          padding-right: 4px;
          margin: 0 !important;
        }
      }
    }

    .live_botom {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 30px;
      color: #666666;
      font-weight: 400;

      .img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          margin: 0 !important;
        }
      }
    }
  }

  .z {
    color: #e0671b;
    font-size: 14px;
    padding: 12px 54px;
    border-radius: 20px;
    background: #fcf4dd;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;

    img {
      width: 18px !important;
      height: 18px !important;
      margin: 0 10px 0 0 !important;
    }
  }

  .j {
    color: #3e7eff;
    font-size: 14px;
    padding: 12px 54px;
    border-radius: 20px;
    background: rgba(62, 126, 255, 0.1);
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;

    img {
      width: 18px !important;
      height: 18px !important;
      margin: 0 10px 0 0 !important;
    }
  }

  .e {
    color: #999999;
    font-size: 14px;
    padding: 12px 54px;
    border-radius: 20px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;

    img {
      width: 18px !important;
      height: 18px !important;
      margin: 0 10px 0 0 !important;
    }
  }
}

::v-deep .el-select {
  width: 100%;
}

::v-deep .el-form-item--mini.el-form-item {
  margin-bottom: 30px;
}

::v-deep .el-form-item__error {
  top: 20px;
}

.ml23 {
  margin-left: 23px;
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
}

::v-deep .contract_desc {
  width: 100%;
  height: 60vh;
}
</style>
