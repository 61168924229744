<template>
  <div>
    <div class="class_title">
      <!-- <div>我的课程</div> -->
      <h2>我的课程</h2>
      <div style="display: flex; align-items: center" v-if="!nocourse">
        <div class="lc">
          <img src="../../assets/img/leftla.png" alt="" />
        </div>
        <div class="navlist">
          <span
            v-for="item in navlist"
            :key="item.id"
            :class="nav == item.id ? 'span' : ''"
            @click="getlistbox(item.id)"
          >
            {{ item.name }}
          </span>
        </div>
        <div class="lc">
          <img src="../../assets/img/rightla.png" alt="" />
        </div>
      </div>
    </div>
    <div class="class_box" :style="nocourse ? 'margin-top:10px;' : ''">
      <div class="studyroom">
        <div class="listbox" v-if="!nocourse">
          <el-collapse accordion>
            <el-collapse-item
              v-for="item in listbox"
              :key="item.chapter_category_name"
            >
              <template slot="title">
                <div class="tit">
                  <div class="titl">
                    <span>
                      <img src="../../assets/img/class.png" alt="" />
                      {{ item.chapter_category_name }}</span
                    >
                  </div>
                  <div class="titr">
                    <div class="prosess">
                      <div class="prosess_t">
                        <div
                          class="is"
                          :style="`width:${
                            (item.video_finish_count / item.video_all_count) *
                              100 <
                            5
                              ? 5
                              : (item.video_finish_count /
                                  item.video_all_count) *
                                100
                          }%`"
                        ></div>
                      </div>
                      <div class="prosess_b">
                        已学{{
                          Math.ceil(
                            (item.video_finish_count / item.video_all_count) *
                              100
                          )
                        }}%
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="child"
                v-for="child in item.list"
                :key="child.chapter_id"
                @click="gomyclass(item, child)"
              >
                <div class="childl">
                  {{ child.name }}
                </div>
                <div class="prosess">
                  <div class="prosess_t">
                    <div
                      class="is"
                      :style="`width:${
                        (child.video_finish_count / child.video_all_count) *
                          100 <
                        5
                          ? 5
                          : (child.video_finish_count / child.video_all_count) *
                            100
                      }%`"
                    ></div>
                  </div>
                  <div class="prosess_b">
                    已学{{
                      Math.ceil(
                        (child.video_finish_count / child.video_all_count) * 100
                      )
                    }}%
                  </div>
                </div>
                <div class="childr">
                  <span> 查看讲义 </span>
                  <span> 去学习 </span>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <div
          v-if="list.list && list.list.length > 0"
          class="l"
          style="background: #f5f5f5"
        >
          <div class="listcard" style="padding: 0; background: #f5f5f5">
            <div
              class="card card2"
              v-for="item in list.list"
              :key="item.id"
              @click="tostudy(item)"
            >
              <div class="cardl">
                <img :src="item.image" alt="" />
                <div class="mask"></div>
                <span class="but">立即学习</span>
              </div>
              <div class="cardr">
                <h3>{{ item.name }}</h3>
                <div class="desc">
                  {{ descto(item.children) }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="l" v-else>
          <div style="background: #f5f5f5">
            <img
              src="../../assets/img/2038a5c41907016ce18d43e60176344.png"
              alt=""
              style="margin-top: -6px"
            />
          </div>
          <div class="listcard">
            <div
              class="card"
              v-for="item in list"
              :key="item.goods_id"
              @click="togoods(item)"
            >
              <div class="cardl">
                <img :src="item.images[0]" alt="" />
              </div>
              <div class="cardr">
                <h2>{{ item.title }}</h2>
                <div class="spanlist">
                  <span
                    v-for="spans in item.serve_content.split('|')"
                    :key="spans"
                  >
                    {{ spans.trim() }}
                  </span>
                </div>
                <div class="botom">
                  <div class="price">
                    ￥{{ item.course_min_price }}-{{ item.course_max_price }}
                  </div>
                  <div class="but">去看看 >></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="r" v-if="nocourse">
          <div class="tit" style="margin: 0">
            <h2>课程问题</h2>
            <a href="/help/help.html?id=kcwt" target="_blank"> 更多问题>> </a>
          </div>
          <div class="list">
            <a href="/help/help.html?id=kcwt" target="_blank">
              1.听不了课怎么办？
            </a>
            <a href="/help/help.html?id=kcwt" target="_blank">
              2.如何在电脑、手机和IPAD下载课程、课件怎...
            </a>
            <a href="/help/help.html?id=kcwt" target="_blank">
              3.如何获取课堂配套资料？
            </a>
            <a href="/help/help.html?id=kcwt" target="_blank">
              4.资料打不开怎么办？
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Mystudys",
  data() {
    return {
      nocourse: false,
      navlist: [],
      listbox: [],
      list: [],
      nav: 1165,
    };
  },
  created() {
    api.my_course_cate().then((res) => {
      // console.log(res);
      if (res.data.data.length <= 0) {
        this.nocourse = true;
        api.user_recommend_goods().then((res) => {
          this.list = res.data.data;
        });
      } else {
        this.navlist = res.data.data;
        this.getlistbox(res.data.data[0].id);
      }
    });
  },
  methods: {
    gomyclass(a, b) {
      window.open(
        `/myClass/8/${a.chapter_category_id}/${this.nav}?threeid=${b.chapter_id}`
      );
      // this.$router.push({
      //   path: `/myClass/8/${a.chapter_category_id}/${this.nav}`,
      //   query: { threeid: b.chapter_id },
      // });
    },
    togoods(e) {
      this.$router.push({
        path: `/shopdetail/${e.two_coursecate_id}/${e.goods_id}`,
      });
    },
    descto(a) {
      let c = [];
      a.forEach((e) => {
        c.push(e.name);
      });
      return c.join("、");
    },
    getlistbox(e) {
      this.nav = e;
      api.chapter_list_v1({ two_coursecate_id: e }).then((res) => {
        // console.log(res);
        this.listbox = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.child {
  display: flex;
  align-items: center;
  padding: 18px 15px;
  border-bottom: 1px solid #f5f5f5;
  .childl:hover {
    background: url("../../assets/img/zhangjie123hover.png") no-repeat left;
    background-size: 24px;
    color: #3f7dff;
  }
  .childl {
    padding-left: 27px;
    cursor: pointer;
    width: 400px;
    background: url("../../assets/img/zhangjie123.png") no-repeat left;
    background-size: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    img {
      width: 24px;
    }
  }
  .childr {
    display: flex;
    width: 200px;
    margin-left: 47px;
    justify-content: space-between;
    span:hover {
      transform: translateY(-5px);
    }
    span {
      transition: all 0.3s;
      width: 85px;
      height: 31px;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
      text-align: center;
      cursor: pointer;
      line-height: 31px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    span:last-child {
      border: 1px solid #3f7dff;
      box-shadow: 0px 0px 9px 0px rgba(63, 125, 255, 0.4);
      color: #3f7dff;
    }
  }
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
  margin: 0px 55px 15px;
  background: #fafafa;
}
.prosess {
  display: flex;
  flex-direction: column;
  .prosess_t {
    width: 130px;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    background: #ebebeb;
  }
  .prosess_t .is {
    height: 100%;
    border-radius: 5px;
    background: #3f7dff;
  }
  .prosess_b {
    font-size: 12px;
    margin-top: 5px;
    line-height: 1;
    font-weight: 500;
    color: #000000;
  }
}
.tit {
  display: flex;
  margin: 15px 0;
  align-items: center;
  .titl {
    display: flex;
    line-height: 1;
    width: 470px;
    span {
      display: flex;
      font-size: 18px;
      font-weight: 500;
      color: #3f7dff;
      align-items: center;
      padding: 10px 15px;
      background: #ebf2ff;
      border-radius: 25px;
      img {
        width: 22px;
      }
    }
  }
}
::v-deep .el-collapse-item__wrap::after {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% + 60px);
  position: absolute;
  left: 45px;
  z-index: 1;
  background: #ebf2ff;
  top: -30px;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: none;
  position: relative;
  overflow: inherit;
}
::v-deep .el-collapse {
  border-bottom: none;
}
::v-deep .el-collapse-item__header {
  margin: 0 25px;
  height: auto;
}
::v-deep .el-collapse-item__arrow.is-active:before {
  border-left: 10px solid #3f7dff;
}
::v-deep .el-icon-arrow-right:before {
  content: "";
  display: block;
  border-top: 7px solid transparent;
  border-left: 10px solid #eee;
  border-bottom: 7px solid transparent;
}
::v-deep .el-collapse {
  border-top: none;
}
.listbox {
  width: 100%;
  background: #fff;
  min-height: 594px;
}
.class_title {
  background: #fff;
  display: flex;
  border-bottom: 1px solid #eee;
  align-items: center;
  h2 {
    padding: 20px 33px 10px;
    font-size: 18px;
    min-width: 72px;
    box-sizing: border-box;
    font-weight: 500;
    box-sizing: border-box;
    color: #000000;
  }
  .lc {
    display: flex;
    align-items: center;
    margin: 0 27px;
    img {
      width: 18px;
      cursor: pointer;
    }
  }
  .navlist {
    display: flex;
    width: 668px;
    align-items: center;
  }
  .navlist span {
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
    color: #000000;
    margin-top: 7px;
    padding: 14px 20px 12px;
  }
  .navlist .span {
    border-right: 1px solid #eee;
    position: relative;
    border-top: 2px solid #3f7dff;
    border-left: 1px solid #eee;
  }
  .navlist .span::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: #fff;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  .navlist .span::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 4px solid #3f7dff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  // div {
  //   width: 65px;
  //   height: 59px;
  //   font-size: 16px;
  //   font-family: PingFang SC;
  //   font-weight: 500;
  //   color: #3a99f9;
  //   line-height: 62px;
  //   border-bottom: 3px solid #3a99f9;
  // }
}
.studyroom {
  display: flex;
  // margin-top: 10px;
  justify-content: space-between;
  .l {
    width: 580px;
    // overflow: hidden;
    background: #fff;
    img {
      width: 100%;
      vertical-align: bottom;
    }
    .listcard {
      padding: 20px 30px 0;
      .card2 {
        padding: 21px 30px;
        margin-bottom: 20px;
        border: none;
        background: #fff;
        .cardl {
          position: relative;
          .mask {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            position: absolute;
            transition: all 0.3s;
            top: 0;
            left: 0;
          }
          .but {
            padding: 7px 25px;
            position: absolute;
            transition: all 0.3s;
            top: 115%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            background: #3e7eff;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }
      .card2:hover {
        .but {
          top: 50% !important;
        }
        .mask {
          opacity: 1 !important;
        }
      }
      .card {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        cursor: pointer;
        margin-bottom: 20px;
        border-bottom: 1px solid #efefef;
        .cardl {
          width: 220px;
          height: 138px;
          overflow: hidden;
          margin-right: 20px;
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            line-height: 1;
          }
        }
        .cardr {
          line-height: 1;
          cursor: pointer;
          h2 {
            margin-top: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #000b2f;
            font-size: 22px;
          }
          h3 {
            font-weight: 500;
            margin-bottom: 10px;
            color: #000b2f;
            font-size: 22px;
          }
          .desc {
            max-width: 284px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
          }
          .spanlist {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            span {
              padding: 3px 11px;
              border-radius: 2px;
              color: #f8872a;
              background: #ffece2;
              font-size: 14px;
              margin-right: 10px;
              font-weight: 400;
            }
            span:nth-child(2) {
              color: #9d55ec;
              background: #e8dffa;
            }
            span:nth-child(3) {
              color: #2483f3;
              background: #daebff;
              margin-right: 0;
            }
          }
          .botom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .price {
              font-weight: 500;
              color: #ff1800;
              font-size: 20px;
            }
            .but {
              padding: 7px 25px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              background: #3e7eff;
              border-radius: 20px;
              cursor: pointer;
            }
          }
        }
        .cardr:hover .but {
          animation: myanimo 1s infinite;
        }
      }
    }
  }
  .r {
    width: 350px;
    overflow: hidden;
    background: #fff;
    line-height: 1;
    height: 180px;
    .list {
      padding: 16px 20px 0;
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
    .tit {
      padding: 17px 20px;
      background: #fff url("../../assets/img/question@2x.png") no-repeat center;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 16px;
        font-weight: 500;
        color: #000b2f;
      }
      a {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
@keyframes myanimo {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
</style>